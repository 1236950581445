import React from 'react';
import { css } from '@emotion/core';
import Navigation from './navigation';
import Social from './social';

const Header  = ({lan}) => {
    
   

    return ( 
        <header
            css={css`
                background-color: #881111;
                padding: 1rem;
            `}
        >
            <div
                css={css`
                    max-width: 90%;
                    margin: 0 auto;
                    text-align: center;
                    align-items: center;

                    @media (min-width: 768px) {
                        display: flex;
                        justify-content: space-between;
                    }
                `}
            >
                <Navigation lan={lan} />
                <Social lan={lan} />
            </div>
        </header>
    );
}
 
export default Header;