import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';




const NavSocial = styled.nav`
    display: flex;
    flex-direction: column;
    padding: 1 auto 1 auto;

    &:last-of-type {
        margin-right: 0;
    }

    @media (min-width: 768px) {
        padding: 0;
        flex-direction: row;
    }
`;

const LinkSocial = styled.a`
    padding: .7rem;
    margin-right: 1;
    color: #ccc;
    &:hover {
        color: #eee;
    }
`;

const Social = ({lan}) => {

    const { whatsapp, telegram, twitter, spanish, english } = useStaticQuery( graphql `
        query {
            whatsapp: file(relativePath: {eq: "whatsapp.png"}) {
                publicURL
            }
            telegram: file(relativePath: {eq: "telegram.png"}) {
                publicURL
            }
            twitter: file(relativePath: {eq: "twitter.png"}) {
                publicURL
            }
            spanish: file(relativePath: {eq: "spanish.png"}) {
                publicURL
            }
            english: file(relativePath: {eq: "english.png"}) {
                publicURL
            }
        }
    `);


    return ( 
        <NavSocial>
            <div>
                <LinkSocial href="whatsapp://send?text=El%20mejor%20catálogo%20de%20acompañantes%20en%20Colombia%20https://lacelestina.co" data-action="share/whatsapp/share">
                    <img src={whatsapp.publicURL} alt={ lan === 'es' ? 'Acompañantes Colombia' : 'Colombian Companions' } />
                </LinkSocial>
                <LinkSocial href="https://t.me/celestinacol">
                    <img src={telegram.publicURL} alt={ lan === 'es' ? 'Acompañantes Colombia' : 'Colombian Companions' } />
                </LinkSocial>
                <LinkSocial href="https://twitter.com/LaCelestinagirl">
                    <img src={twitter.publicURL} alt={ lan === 'es' ? 'Acompañantes Colombia' : 'Colombian Companions' } />
                </LinkSocial>
                <LinkSocial href={ lan === 'es' ? '/en/' : '/'}>
                    <img src={  lan === 'es' ? english.publicURL : spanish.publicURL} alt={ lan === 'es' ? 'Acompañantes Colombia' : 'Colombian Companions' } />
                </LinkSocial>
            </div>
        </NavSocial>
    );
}
 
export default Social;