import React from 'react';
import { Global, css } from '@emotion/core';
import { Helmet } from 'react-helmet'
import Header from './header';

const Layout = ({ children, lan } ) => {

    return ( 
        <>
            <Global
                styles={css`
                    html {
                        font-size: 62.5%;
                        box-sizing: border-box;
                    }
                    *, *:before, *:after {
                        box-sizing: inherit;
                    }
                    body{
                        font-size: 1.6rem;
                        line-height: 2;
                        font-family: 'Lato', sans-serif;
                    }
                    h1, h2, h3 {
                        margin: 0;
                        line-height: 1.5;
                    }
                    h1, h2 {
                        text-align: center;
                        font-family: 'Lato', sans-serif;
                        font-weight: 300;
                    }
                    h3 {
                        font-family: 'Roboto', sans-serif;
                    }
                    ul {
                        list-style: none;
                        margin: 0;
                        padding: 0;    
                    }
                    .contenedor {
                        max-width: 120rem;
                        margin: 0 auto;
                        width: 95%;
                    }
                    img {
                        max-width: 100%;
                    }
                `}
            />            
            <Helmet>
                <html lang={lan} />
                <script>  
                    {`(function(d, w, c) {
                            w.BrevoConversationsID = '5f160ea802cea953b038ac20';
                            w[c] = w[c] || function() {
                                (w[c].q = w[c].q || []).push(arguments);
                            };
                            var s = d.createElement('script');
                            s.async = true;
                            s.src = 'https://conversations-widget.brevo.com/brevo-conversations.js';
                            if (d.head) d.head.appendChild(s);
                        })(document, window, 'BrevoConversations');
                    `}
                </script>
            </Helmet>       
            <Header lan={lan} />
            
            {children}
        </>
     );
}
 
export default Layout;