import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';

const Nav = styled.nav`
    display: flex;
    flex-direction: column;
    padding-bottom: 3rem;

    @media (min-width: 768px) {
        padding: 0;
        flex-direction: row;
    }
`; 


const NavLink = styled(Link)`
    color: #FFF;
    font-weight: 400;
    font-size: 1.6rem;
    font-family: 'PT Sans', sans-serif;
    text-decoration: none;
    padding: .7rem;
    margin-right: 1;

    &:last-of-type {
        margin-right: 0;
    }
    &:hover {
        background-color: #efefef;  
        text-decoration: none;
        color: #f40b59;    
    }

    @media (min-width: 768px) {
        color: #ccc;    
    }
`; 

const Navigation = ({lan}) => {
    return ( 
        <Nav>
            <NavLink to={ lan === 'es' ? '/' : '/en' } activeClassName="current-page" >{ lan === 'es' ? 'PRINCIPAL' : 'HOME'}</NavLink>
            <NavLink to={lan === 'es' ? '/trabajos' : '/jobs'} activeClassName="current-page">{ lan === 'es' ?'TRABAJA CON NOSOTROS' : 'JOBS'}</NavLink>
            <NavLink to={'http://lacelestina.co/blog'} activeClassName="current-page">VIDEO BLOG</NavLink>
        </Nav>
     );
}
 
export default Navigation;